.card{
  width: 720px;
  height: 104px;
  background-color: #30b455;
}

.Cuerpoc{
  width: 720px;
  height: 1576px;
  background-color: white;
}

.Es{
  font-family: Poppins;
  padding-top: 25px;
  font-size: 35px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.51;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.uno{
  font-family: Poppins;
  font-size: 31px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
  padding-left: 59px;
  padding-top: 36px;
}

.dos{
  font-family: Poppins;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
  padding-left: 10px;
  padding-top: 36px;
  float: left;
}
.tres{
  font-family: Poppins;
  font-size: 31px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
  padding-left: 59px;
  padding-top: 36px;
}

.cuatro{
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
  padding-top: 20px;
  color: #ffffff;
  float: left;
}


.Tab{
 width: 100%;
}

.Tab1{
  width: 330px;
}

.img1{
  margin-top: 25px;
  float: left;
  margin-right: 5px;
  margin-left: 15px;
}

.img2{
  margin-top: 25px;
  float: left;
  margin-right: 5px;
  margin-left: 10px;
}

.img3{
  margin-top: 42px;
  float: right;
 
}

.foot{
  padding-top: 15px;
  height: 134px;
  width: 100%;
}

.img4{
  margin-right: 30px;
  float: right;
 
}

.img5{
  margin-left: 30px;
  float: left;
 
}

.img6{
  margin-top: 20px;
}

.img7{
  margin-top: 25px;
  float: left;
  margin-right: 20px;
  margin-left: 30px;
}

.img8{
  width: 100%;
}

.final{
  width: 100%;
}

.btn1{
  width: 350px;
  height: 112.5px;
  object-fit: contain;
  border-radius: 56px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fbbe2d;
}

.btn2{
  width: 350px;
  height: 112.5px;
  object-fit: contain;
  border-radius: 56px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #30b455;
}